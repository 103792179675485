import React, { FC } from "react"

import { InfoBox } from "./InfoBox"
import styled from "styled-components"

interface InfoBoxesProps {}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  max-width: 1000px;
  > :not(:first-child) {
    margin-top: 20px;
    margin-left: 0;
  }
  ${({ theme }) => theme.breakpoints.up("sm")} {
    flex-direction: row;
    > :not(:first-child) {
      margin-top: 0;
      margin-left: 20px;
    }
  }
`

export const InfoBoxes: FC<InfoBoxesProps> = () => {
  return (
    <Container>
      <InfoBox
        title="Aktualna baza testów"
        description="Sprawdź się w oficjalnych testach z&nbsp;Ministersfa Infrastruktury. Pracujemy na aktualnych zadaniach, nasi eksperci wytłumaczą ci każdą zagwostkę w ruchu drogowym."
      />
      <InfoBox
        title="Komfort kursu"
        description="Samemu możesz dopasować kurs pod siebie. Wybierasz w jaki sposób chcesz się uczyć, filmy, animacje? Nie ma sprawy! Możesz równiesz słuchać wykładów. "
      />
      <InfoBox
        title="Próbny Egzamin"
        description="Jesteśmy w stanie przeprowadzić próbny egzamin dokładnie taki jaki czeka cię w WORD Łódź. Dzięki temu sprawdzisz czy zdałbyś oficjalny egzamin. "
      />
    </Container>
  )
}

export default InfoBoxes
